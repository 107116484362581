/*Equipo de familias*/
#EquipoDeFamilias {
  padding: 60px 0;
  //background: #a3c734;   //COLOR ORIGINAL
  background: #ff661a; //COLOR SECUNDARIO
  color: #fff;
}

#EquipoDeFamilias .row {
  display: flex;
  .col-md-6 {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 992px) {
  #EquipoDeFamilias .row {
    display: block;
  }
}

#EquipoDeFamilias h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #fff;
}
#EquipoDeFamilias h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#EquipoDeFamilias .about-text {
  margin-top: 15%;
}

@media (max-width: 991px) {
  #EquipoDeFamilias .about-text {
    margin-top: 0;
  }
}

#EquipoDeFamilias img {
  width: 520px;
  margin-top: 10px;
  border-radius: 5px;
  margin-top: 10%;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

@media (max-width: 992px) {
  #EquipoDeFamilias img {
    margin: 50px 0;
  }
}

#EquipoDeFamilias p {
  line-height: 24px;
  margin: 30px 0;
  text-align: justify;
}

@media (max-width: 768px) {
  #EquipoDeFamilias img {
    margin: 50px 0;
  }
}
