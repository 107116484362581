/* Header Section */
/*.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url(../img/intro-bg2.jpg) center center no-repeat;
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}*/

#stage {
    width: 100%;
    //height: 113%;
    height: 100%;
  }
  
  #stage div {
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
  }
  
  #stage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  #stage div:nth-of-type(1) {
    animation-name: fader;
    animation-delay: 7s;
    animation-duration: 1s;
    z-index: 20;
  }
  #stage div:nth-of-type(2) {
    z-index: 10;
  }
  #stage div:nth-of-type(n+3) {
    display: none;
  }
  
  @keyframes fader {
    from { opacity: 1.0; }
    to   { opacity: 0.0; }
  }
  
  /*.intro .overlay {
    background: rgba(0, 0, 0, 0.2);
  }*/
  .intro{
    position: relative;
    text-align: center;
  }
  
  .intro h1 {
    font-family: "Optimus Princeps Semi Bold";
    color: #fff;
    opacity: 0.9;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  
  @media (max-width: 480px) {
    .intro h1 {
      font-size: 60px;
      font-weight: 700;
    }
  }
  
  @media (max-width: 330px) {
    .intro h1 {
      font-size: 50px;
      font-weight: 700;
    }
  }
  
  .textoIntro {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 20;
    transform: translate(-50%, -50%);
  }
  
  .intro p {
    color: #fff;
    font-size: 35px;
    line-height: 30px;
    margin: 0 auto;
    font-family: "Optimus Princeps Semi Bold";
    margin-bottom: 60px;
  }
  header .intro-text {
    padding-top: 350px;
    padding-bottom: 200px;
    text-align: center;
  }