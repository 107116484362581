/* Us Section */
  #Nosotros {
    padding: 100px 0;
    padding-bottom: 50px;
    background: #f6f6f6;
  }

  #Nosotros .row {
    display: flex;
    .col-md-6{
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 992px) {
    #Nosotros .row {
      display: block;
    }
  }

  #Nosotros h3 {
    font-size: 22px;
    margin: 0 0 20px;
    
  }
  #Nosotros h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  #Nosotros h2::after {
    position: absolute;
    content: "";
    background: #FF5500; //ORIGINAL
    //background: #33742c; //SECUNDARIO
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
  }
  #Nosotros .about-text li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
  }
  #Nosotros .about-text li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 11px;
    font-weight: 300;
    padding-right: 8px;
  }
  #Nosotros img {
    width: 520px;
    border-radius: 5px;
    margin-top: 10%;
    background: #fff;
    border-right: 0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  }

  @media (max-width: 992px) {
    #Nosotros img {
      margin: 50px 0;
    }
  }

  #Nosotros p {
    line-height: 24px;
    margin: 30px 0;
    text-align: justify;
  }