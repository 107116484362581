#MyGallery {
  padding: 100px 0;
}

/*.cuerpoGaleria {
  margin: 0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: 32.7%;
  height: 400px;
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 1200px) {
    width: 49%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  &:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
  }

  img {
    width: 100%;
  }

  div {
    position: absolute;
    bottom: 25px;
    left: 0;
    color: #fff;
    padding: 0 10 0 10;
    text-align: center;
    h2 {
      font-size: 51.5px;
      color: #fff;
      line-height: 50px !important;
      text-shadow: 0 0.5px 0.5px rgb(0 0 0 / 80%);
      text-transform: uppercase;
      margin: 0 0 0.5rem;
    }
    p {
      margin: 0;
      text-shadow: 0 0.5px 0.5px rgb(0 0 0 / 80%);
    }
  }
}
*/