/* Team Section */
#team {
  padding: 100px 0;
  background: #f6f6f6;
}
#team h4 {
  margin: 5px 0;
}
#team .team-img {
  width: 240px;
  box-shadow: 5px 5px #60b071;
}
#team .thumbnail {
  background: transparent;
  border: 0;
}
#team .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}
