/*Mission and Vision section*/
#MissionVision {
    padding: 100px 0;
  }
  #MissionVision p {
    line-height: 24px;
    margin: 30px 0;
    text-align: justify;
  }
  #MissionVision .section-title {
    text-align: center;
    margin-bottom: 0px;
  }