/* courseTeachers Section */
#courseTeachers {
  padding: 100px 0 100px;
  background: #a3c734;   //COLOR ORIGINAL
  //background: #ff661a; //COLOR SECUNDARIO
  color: rgba(255, 255, 255, 0.75);
}
#courseTeachers .section-title {
  margin-bottom: 40px;
}
#courseTeachers .section-title p {
  font-size: 16px;
}
#courseTeachers .section-title h2 {
  margin-left: 20px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#courseTeachers .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}

#courseTeachers .teacherCard {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 30px;
  margin: 25px;
  background-color: #f6f6f6;
}

#courseTeachers img {
  border-radius: 5px;
  width: 100%;
  position: relative;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.315);
}

#courseTeachers .bottomContainer {
  margin-top: 20;
}

#courseTeachers .bottomContainer h2 {
  font-size: 20px;
  font-weight: 700;
  color: #525252;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin: 0 0 5px 0;
}

#courseTeachers .bottomContainer p {
  color: #888;
  font-size: 1.5rem;
}

@media (max-width: 993px) and (min-width: 450px) {
  .row1-container {
    margin-left: 15%;
  }
  .row2-container {
    margin-left: 15%;
  }

  .teacherCard {
    text-align: center;
    width: 75%;
  }

  #courseTeachers .teacherCard {
    padding: 30px;
    margin: 25px;
  }
}

@media (min-width: 993px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row2-container {
    margin-top: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-down {
    position: relative;
    top: 250px;
  }
  .teacherCard {
    width: 25%;
    text-align: center;
  }
}
