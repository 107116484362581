/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}

.container-img-menu {
  position: absolute;
  width: 200px;
  height: 135px;
  opacity: 0.3;
  top: 0;
  background-color: #fff;
  left: 10px;
}
.img-menu-top {
  position: absolute;
  width: 220px;
  height: auto;
  top: 0;
  left: 50px;
  transition: all 0.5s ease-in-out;
}

.img-menu-bot {
  position: absolute;
  width: 120px;
  height: auto;
  top: 0;
  left: 50px;
  transition: all 0.5s ease-in-out;
}

.text-menu {
  font-family: "Cookie", cursive;
  text-align: center;
  width: 200px;
  line-height: 20px;
  margin: 5px 0 0 115px;
  font-size: 24px;
  font-weight: 550;
  color: #33742c;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .text-menu {
    margin: 5px 0 0 100px;
  }
  .img-menu-bot {
    left: 10px;
  }
  .img-menu-top {
    left: 10px;
  }
}

@media (max-width: 1050px) {
  .img-menu-top {
    width: 150px;
  }
}
@media (max-width: 992px) {
  .img-menu-top {
    width: 120px;
  }
}

#menu.navbar-default {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
}

#menu.navbar-default .navbar-nav li a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  opacity: 0.85;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 5px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: 9px 10px 0;
  color: black;
  background-color: #fff;
}

#menu.navbar-default .navbar-nav li a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #60b071 0%, #9add6f 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav li a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav .active a,
.navbar-default .navbar-nav .active a:hover,
.navbar-default .navbar-nav .active a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav .active a:after,
.navbar-default .navbar-nav .active a:hover:after,
.navbar-default .navbar-nav .active a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #60b071 0%, #9add6f 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover .icon-bar {
  background-color: #608dfd;
}
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: #FF5500; //ORIGINAL
  //background: #33742c; //SECUNDARIO
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #9add6f 0%, #60b071 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}
