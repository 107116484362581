/*Mission and Vision section*/
#Flags { 
    padding-bottom: 50px; 
    //padding: 100px 0;
    background: #f6f6f6;
    width: 100%;
}
#Flags .section-title {
    text-align: center;
    margin-bottom: 30px;
}

.primerFila {
    margin-left:10%;
}

.segundaFila {
    margin-left:20%;
}

.tercerFila {
    margin-top: 1.5%;
    margin-left:10%;
}

.flags{
    margin: 10px;
    width: 65px;
    height: 65px;
}
 
@media (max-width: 1200px){
    .primerFila {
        margin-left:0%;
    }
    
    .segundaFila {
        margin-left:12%;
    }
    
    .tercerFila {
        margin-left:0%;
    }
}

@media (max-width: 992px){

    .segundaFila {
        margin-left:0%;
    }
}

@media (max-width: 768px){
    .flagContainer {
        float: left;
        text-align: center;
    }
}
  
@media (max-width: 400px){
    #Flags {
        width: 110%;
    }
}
