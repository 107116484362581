/* Founding Team Section */
#fTeam {
  padding: 100px 0;
  background: #f6f6f6;
}
#fTeam h4 {
  margin: 5px 0;
}
#fTeam .team-img {
  width: 240px;
  box-shadow: 5px 5px #FF5500;
  //box-shadow: 5px 5px #33742c;
  //border: 2px solid #FF5500;
  //border-radius: 3px;
  //width: 100%;
}
#fTeam .thumbnail {
  background: transparent;
  border: 0;
}
#fTeam .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}
