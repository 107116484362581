/*Certification section*/
#Certificacion {
    padding: 100px 0;
    background: #a3c734;   //COLOR ORIGINAL
    //background: #ff661a;  //COLOR SECUNDARIO
    color: #fff;
  }

  #Certificacion .section-title {
    text-align: center;
    margin-bottom: 30px;
}

  #Certificacion h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: #fff;
  }
  
  #Certificacion h2::after {
    background: rgba(255, 255, 255, 0.3);
  }

  #Certificacion img {
    background: #fff;
    border-radius: 50%;
    height: auto;
    width: 40%;
    margin-left:30%;
  }

  #Certificacion p {
    line-height: 24px;
    margin: 30px 0;
    font-size: 17px;
    text-align: justify;
  }

  
  @media (max-width: 992px) {
    #Certificacion .section-title {
      padding-top: 10%;
    }

    #Certificacion img {
      width: 50%;
      margin-left: 25%;
    }
  }